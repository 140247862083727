// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('💖 yutojp.com 🎉')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
import * as Turbo from '@hotwired/turbo'
Turbo.start()
// import '@hotwired/turbo-rails'
//
// import * as ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css

// Entry point for the build script in your package.json
import "../controllers"

import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core";
import '@fortawesome/fontawesome-free'
library.add(fas, far, fab)

// import "@rails/actiontext"
import '../src/main'
import "@fortawesome/fontawesome-free/js/all"

import 'flowbite/dist/flowbite.turbo'
import 'js-toc'

import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

import '@/builds/index.css'

document.addEventListener('turbo:load', () => {
  fbAsyncInit()

  // Clear console
  // const environment = document.body.getAttribute('data-environment');
  // if (environment === 'production') {
  //   var counter = 0;
  //   var i = setInterval(function(){
  //     console.clear()
  //     counter++;
  //     if(counter === 10) {
  //       clearInterval(i);
  //     }
  //   }, 500);
  // }
})
